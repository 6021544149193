import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './plugin/i18n';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import 'aos/dist/aos.css';
const recaptchaOptions = {
    siteKey: '6LfErioaAAAAAHVLbF4Q1EqilksyrsjDM1dTW9vD',
    loaderOptions: {}
};
const toastOptions = {
    position: 'bottom-left',
    transition: 'Vue-Toastification__fade',
    hideProgressBar: true,
    closeOnClick: false,
    timeout: 3000
};
const app = createApp(App);
app
    .use(store)
    .use(router)
    .use(i18n)
    .use(Toast, toastOptions)
    .use(VueReCaptcha, recaptchaOptions);
app.directive('visible', (el, binding) => {
    el.style.visibility = binding.value ? 'visible' : 'hidden';
});
app.mount('#app');
